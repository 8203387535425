<template>
  <div class="list-area">
    <el-row :gutter="6">
      <el-col :span="6">
        <el-row type="flex" align="middle">
          <span style="white-space: nowrap"> 日期：</span>
          <el-date-picker
            size="small"
            type="daterange"
            range-separator="至"
            v-model="searchData.date"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-row>
      </el-col>
      <el-col :span="3">
        <el-input
          placeholder="应用包名"
          v-model="searchData.appName"
        ></el-input>
      </el-col>
      <el-col :span="3">
        <el-input
          placeholder="市场版本"
          v-model="searchData.verCode"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="6">
      <el-col :span="3">
        <el-select
          v-model="searchData.countryList"
          placeholder="国家"
          multiple
          clearable
          filterable
          collapse-tags
        >
          <el-option
            v-for="item in countryList"
            :key="item.countryCode"
            :label="item.countryName"
            :value="item.countryCode"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="searchData.languageList"
          placeholder="语言"
          multiple
          clearable
          filterable
          collapse-tags
        >
          <el-option
            v-for="item in languageList"
            :key="item.id"
            :label="item.languageName"
            :value="item.marketLanguageType"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="searchData.installFrom"
          placeholder="下载来源"
          clearable
          filterable
        >
          <el-option
            v-for="item in sourceList"
            :key="item.id"
            :label="item.installFromSource"
            :value="item.installFromSource"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" icon="el-icon-search" @click="getTableList()">
          查询
        </el-button>
        <ExportTable class="ml10" @download="getDownloadList"></ExportTable>
      </el-col>
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
      @customSortChange="customSortChange"
    >
    </PageTable>
  </div>
</template>
<script>
import PageTable from "@/components/PageTableSort.vue";
import ExportTable from "@/components/ExportTable.vue";
import { getCategoryList, getDownloadSource } from "./indexServe";
import { getLanguageList, cmsCountryAll } from "@/common/js/api";
import { MAXSIZEDOWN } from "@/common/constant/constant";
import moment from "moment";
let end = moment(new Date()).format("YYYY-MM-DD");
let start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
start = moment(start).format("YYYY-MM-DD");

export default {
  name: "terminalReport",
  components: { ExportTable, PageTable },
  created() {
    this.fetchCountryList();
    this.fetchLanguageList();
    this.fetchSourceList();
    this.init();
  },
  data() {
    return {
      countryList: [],
      languageList: [],
      sourceList: [],

      searchData: {
        date: [start, end],
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "pt",
          label: "日期",
          align: "center",
          sortable: "custom",
        },
        {
          key: "downloads",
          label: "下载次数",
          align: "center",
          sortable: "custom",
        },
        {
          key: "appName",
          label: "应用包名",
          align: "center",
        },
        {
          key: "ver",
          label: "应用版本",
          align: "center",
        },
        {
          key: "installFrom",
          label: "下载来源",
          align: "center",
        },
        {
          key: "countryName",
          label: "国家",
          align: "center",
        },
        {
          key: "languageName",
          label: "语言",
          align: "center",
        },
        {
          key: "verCode",
          label: "应用市场版本",
          align: "center",
        },
      ],
      visible: false, //控制drawer的显示和隐藏
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
    };
  },

  methods: {
    // 获取下载来源
    async fetchSourceList() {
      const res = await getDownloadSource({
        size: 1000,
        page: 1,
      });
      this.sourceList = res.data.list;
    },
    // 获取语言列表
    async fetchLanguageList() {
      const res = await getLanguageList();
      this.languageList = [].concat(res.data);
    },
    // 获取国家地区列表
    async fetchCountryList() {
      const res = await cmsCountryAll();
      this.countryList = [].concat(res.data);
    },
    customSortChange(column) {
      if (column) {
        if (column.prop === "pt" && column.order === "ascending") {
          this.searchData.OrderType = 1;
        } else if (column.prop === "pt" && column.order === "descending") {
          this.searchData.OrderType = 2;
        } else if (
          column.prop === "downloads" &&
          column.order === "ascending"
        ) {
          this.searchData.OrderType = 3;
        } else if (
          column.prop === "downloads" &&
          column.order === "descending"
        ) {
          this.searchData.OrderType = 4; //降序
        }
        this.getTableList({ page: 1 });
      }
    },
    // 事件监听
    handleChangePage({ page, size }) {
      this.tableData.page = page;
      this.tableData.size = size;
      this.getTableList({ page, size });
    },
    handleOperate(operationType, row) {
      this.operationType = operationType;
      this.visible = true;
      if (operationType === "create") {
        this.detailData = {};
      }
    },

    // 后端请求
    async getDownloadList(callback) {
      const res = await this.getTableList({ page: 1, size: MAXSIZEDOWN }, true);
      if (res) {
        const headerDisplay = {};
        this.columns.forEach((item) => {
          const key = item.key || item.slot;
          if (key !== "action") {
            headerDisplay[key] = item.label;
          }
        });
        // 处理导出的数据
        const list = res.list;
        callback({
          fileName: "终端用户APP统计",
          headerDisplay,
          list,
        });
      }
    },
    // 获取列表
    async getTableList(params, isDownload) {
      const obj = {
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      };
      if (obj.date && obj.date.length > 0) {
        obj.startTime = obj.date[0];
        obj.endTime = obj.date[1];
      }
      const res = await getCategoryList(obj);
      if (res && res.code === 0) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },

    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
